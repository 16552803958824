@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  border: solid 1px var(--colorGrey200);
  border-radius: 10px;
  background: var(--colorWhite);
  transition: var(--transitionStyle);
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    transition: var(--transitionStyle);
    box-shadow: var(--boxShadowPopupLight);
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-top: 10px;
}

.priceValue {
  font-weight: var(--fontWeightBold);
  font-size: 20px;
  line-height: 120%;
  /* Remove default margins from font */
  margin: 0;
  padding: 0;
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorGrey900);
  margin: 0 0 10px 0;
  line-height: 110%;
  font-size: 20px;
}
p {
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey900);
  font-size: 16px;
  line-height: 140%;
  /* Remove default margins from font */
  margin: 0;
  padding: 0;
  min-height: 200px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.authorInfo {
  font-weight: var(--fontWeightRegular);
  color: var(--colorBlack);
  font-size: 16px;
  line-height: 140%;
  /* Remove default margins from font */
  margin: 0;
  padding: 0;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.bookBtn {
  composes: buttonDefault from global;
  padding: 10px 20px;
  width: auto;
  min-height: auto;
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  display: inline-block;
  text-transform: uppercase;
  margin-top: 10px;
}

.services {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 12px;
  & .pill {
    background-color: var(--colorGrey50);
    border: solid 1px var(--colorGrey100);
    border-radius: 100px;
    padding: 6px 14px;
    font-size: 13px;
    color: var(--colorGrey900);
    line-height: 100%;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: var(--transitionStyle);
    &:hover {
      transition: var(--transitionStyle);
      background-color: var(--colorWhite);
      border-color: var(--colorGrey200);
      box-shadow: var(--boxShadow);
    }
    & .delete {
      color: var(--colorFail);
      font-size: 18px;
      line-height: 100%;
      margin-left: 8px;
    }
  }
}
